import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { TWordForm } from 'src/sections/word/add-edit-word-modal-form';
import { TWordsForm } from 'src/sections/word/add-word-group-modal-form';
import {
  TDictionary,
  TDitionarySettings,
  TList,
  TWord,
} from 'src/types/dictionary';

export const dictionaryApi = createApi({
  reducerPath: 'dictionaryApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api',
    prepareHeaders: (headers, { getState }: { getState: () => any }) => {
      // By default, if we have a token in the store, let's use that for authenticated requests
      const token = getState().auth.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['Dictionary', 'Dictionaries', 'Lists', 'Words'],
  endpoints: (build) => ({
    getDictionaries: build.query<TDictionary[], any>({
      query: () => ({
        url: `/dictionaries/get`,
        method: 'GET',
      }),
      providesTags: ['Dictionaries'],
    }),
    getOneDictionary: build.query<TDitionarySettings, number>({
      query: (id) => ({
        url: `/dictionaries/${id}`,
        method: 'GET',
      }),
      providesTags: (res, error, arg) => ['Dictionary'],
    }),
    addDictionary: build.mutation<null, Omit<TDictionary, 'id' | 'userId'>>({
      query: (body) => ({
        url: `/dictionaries/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Dictionaries'],
    }),
    editDictionary: build.mutation<null, TDictionary>({
      query: (body) => ({
        url: `/dictionaries/edit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Dictionaries', 'Dictionary'],
    }),
    deleteDictionary: build.mutation<null, number>({
      query: (id) => ({
        url: `/dictionaries/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Dictionaries'],
    }),
    getLists: build.query<TList[], number>({
      query: (id) => ({
        url: `/list/get-all/${id}`,
        method: 'GET',
      }),
      providesTags: ['Lists'],
    }),
    addList: build.mutation<null, Omit<TList, 'id'>>({
      query: (body) => ({
        url: `/list/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Lists'],
    }),
    editList: build.mutation<null, TList>({
      query: (body) => ({
        url: `/list/edit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Lists'],
    }),
    deleteList: build.mutation<null, number>({
      query: (id) => ({
        url: `/list/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Lists'],
    }),
    getWords: build.query<TWord[], number>({
      query: (id) => ({
        url: `/words/get-all/${id}`,
        method: 'GET',
      }),
      providesTags: ['Words'],
    }),
    addWord: build.mutation<null, TWordForm>({
      query: (body) => ({
        url: `/words/add`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Words'],
    }),
    editWord: build.mutation<null, TWord>({
      query: (body) => ({
        url: `/words/edit`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Words'],
    }),
    deleteWord: build.mutation<null, number>({
      query: (id) => ({
        url: `/words/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Words'],
    }),
    moveWord: build.mutation<null, { id: number; listId: number }>({
      query: (body) => ({
        url: `/words/move`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Words'],
    }),
    addWordList: build.mutation<null, TWordsForm>({
      query: (body) => ({
        url: `/words/add-word-list`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Words'],
    }),
    deleteWordList: build.mutation<null, { listId: number; wordIds: number[] }>(
      {
        query: (body) => ({
          url: `/words/delete-word-list`,
          method: 'POST',
          body,
        }),
        invalidatesTags: ['Words'],
      },
    ),
  }),
});

export const {
  useGetDictionariesQuery,
  useGetOneDictionaryQuery,
  useAddDictionaryMutation,
  useEditDictionaryMutation,
  useDeleteDictionaryMutation,
  useGetListsQuery,
  useAddListMutation,
  useEditListMutation,
  useDeleteListMutation,
  useGetWordsQuery,
  useAddWordMutation,
  useEditWordMutation,
  useDeleteWordMutation,
  useMoveWordMutation,
  useAddWordListMutation,
  useDeleteWordListMutation,
} = dictionaryApi;
